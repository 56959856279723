import styled from 'styled-components'

export default styled.div`
  .rechart-tooltip-container {
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    background: ${(props) => props.theme.cardTitle};
    color: ${(props) => props.theme.darkBackground};
    .label-container {
      margin-bottom: 6px;
      font-weight: bold;
    }
    .data-key-container {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .circle-color {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-right: 10px;
      }
      .data-value-container {
        font-family: 'Prompt', sans-serif;
        font-size: 10px;
        .data-key {
          margin-right: 4px;
        }
        .data-value {
          font-style: italic;
        }
      }
    }
  }
`
