/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import BarChart1XStyled from './styledComponent'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, Text } from 'recharts'

import colors from '../../colors'

const barStyled = {
  fontSize: 10,
  fontFamily: 'Prompt, sans-serif'
}

class BarChart1XComponent extends React.Component {
  generateBar() {
    return this.props.listBars.map((barData) => (
      <Bar key={barData.name} dataKey={barData.name} fill={barData.color} barSize={20} minPointSize={0.5} />
    ))
  }

  renderTooltip({ active, payload, label }) {
    let listDataTooltip = []
    if (payload) {
      listDataTooltip = payload.map((data) => {
        return (
          <div className="data-key-container" key={`${data.dataKey}${data.value}`}>
            <div className="circle-color" style={{ background: `${data.color}` }} />
            <div className="data-value-container">
              <span className="data-key">{data.dataKey}:</span>
              <span className="data-value">{data.value}</span>
            </div>
          </div>
        )
      })
    }
    return (
      <div className="rechart-tooltip-container">
        <div className="label-container">{label}</div>
        {listDataTooltip}
      </div>
    )
  }

  getData() {
    return this.props.data
  }

  render() {
    return (
      <BarChart1XStyled>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={this.getData()} margin={{ top: 20, right: 15, left: 20, bottom: 30 }}>
            <CartesianGrid x={80} vertical={false} style={{ opacity: 0.3 }} />
            <XAxis dataKey="label" tick={barStyled} axisLine={false} tickLine={false}>
              <Label
                position="bottom"
                value={this.props.xLabel}
                offset={0}
                style={Object.assign({}, barStyled, {
                  fontFamily: 'Prompt-Italic, sans-serif',
                  fontSize: 10,
                  color: colors.barChartFontColor
                })}
                id={this.props.xLabelID}
              />
            </XAxis>
            <Tooltip
              cursor={false}
              wrapperStyle={Object.assign({}, barStyled, {
                fontSize: 10,
                fontWeight: 'bold',
                backgroundColor: colors.cardTitle,
                color: '#000'
              })}
              content={(data) => this.renderTooltip(data)}
            />
            <YAxis
              axisLine={false}
              tick={barStyled}
              tickLine={false}
              label={
                <Text
                  x={-15}
                  y={-50}
                  dx={50}
                  dy={150}
                  offset={0}
                  angle={-90}
                  style={Object.assign({}, barStyled, {
                    fontFamily: 'Prompt-Italic, sans-serif',
                    fontSize: 10,
                    color: colors.barChartFontColor
                  })}
                >
                  {this.props.yLabel}
                </Text>
              }
            ></YAxis>
            {this.generateBar()}
          </BarChart>
        </ResponsiveContainer>
      </BarChart1XStyled>
    )
  }
}

BarChart1XComponent.propTypes = {
  listBars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  yLabel: PropTypes.string,
  xLabel: PropTypes.string,
  xLabelID: PropTypes.string,
  yLabelID: PropTypes.string
}

export default BarChart1XComponent
